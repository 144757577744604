const columnConfig = [
  {
    key: 'date',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      minWidth: '150px',
      textAlign: 'left',
    },
    tdClass: 'text-[#828282]',
  },
  {
    key: 'card_label',
    label: 'Nama Kartu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      fontSize: '110px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
    },
  },
  {
    key: 'download',
    label: '',
    tdStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
    },
  },
]

export default columnConfig
