<template>
  <BCard class="min-h-[100vh]">
    <div class="flex w-full justify-between items-center">
      <div class="flex gap-[12px]">
        <BButton
          variant="primary"
          size="sm"
          class="rounded-[6px]"
          @click="$router.back()"
        >
          <img
            class="w-[20px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-left-2.svg"
            alt="arrow left"
          >
        </BButton>
        <div class="text-[#333333] font-semibold text-[24px]">
          Synchronize Transaksi
        </div>
      </div>
      <BButton
        variant="primary"
        size="sm"
        class="rounded-[6px]"
        @click="getListSyncTransaction"
      >
        <div
          class="flex gap-[5px] text-[16px] items-center"
        >
          <img
            class="h-[16px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/refresh-2.svg"
            alt="arrow left"
          >
          <div>
            Refresh
          </div>
        </div>
      </BButton>
    </div>
    <div class="mt-[20px]">
      <BOverlay
        :show="loading.table"
        spinner-variant="primary"
        variant="light"
        class="mt-[16px]"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div
          :class="items !== null && 'infinte-scroll'"
          @scroll="handleScroll"
        >
          <BTable
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada data yang ditampilkan"
            responsive
          >
            <template #cell(date)="data">
              {{ FULL_DATE_TIME(data.item.date) }}
            </template>
            <template #cell(card_label)="data">
              <div class="flex items-center">
                <span class="k-card text-[#c2c2c2] text-[20px]" />
                <div class="text-nowrap ml-[8px] font-semibold text-[#333333]">
                  {{ data.item.card_name }}
                </div>
                <div class="text-[#626262]  ml-[8px]">
                  ***{{ data.item.last_number }}
                </div>
              </div>
            </template>
            <template #cell(download)="data">
              <div class="flex">
                <div
                  v-if="data.item.status === 'pending'"
                  class="text-[#c2c2c2] rounded-[4px] text-[12px] bg-[#F4F4F4] border-[1px] font-semibold border-[#C2C2C2] flex gap-[8px] px-[8px] py-[4px] items-center"
                >
                  <span class="k-refresh" />
                  <div>Pending</div>
                </div>
                <a
                  v-else
                  class="text-[#333333] hover:text-[#333333] text-[12px] cursor-pointer rounded-[4px] border-[1px] font-semibold border-[#E2E2E2] flex gap-[8px] px-[8px] py-[4px] items-center"
                  :href="data.item.download_url"
                  download
                >
                  <span class="k-import-3 text-[15px]" />
                  <div>Download</div>
                </a>
              </div>
            </template>
          </BTable>
        </div>
      </BOverlay>
    </div>
  </BCard>
</template>

<script>
import { FULL_DATE_TIME } from '@/libs/filterDate'
import { newAxiosIns } from '@/libs/axios'
import columnConfig from './config'

export default {
  data() {
    return {
      FULL_DATE_TIME,
      fields: columnConfig,
      items: [],
      loading: {
        table: false,
      },
    }
  },
  mounted() {
    this.getListSyncTransaction()
  },
  methods: {
    async getListSyncTransaction() {
      const { id } = this.$route.params

      const url = '/komcards/api/v1/card/sync'
      this.loading.table = true

      const params = {
        user_id: id,
      }

      try {
        const respond = await newAxiosIns.request({
          method: 'get',
          url,
          params,
        })

        this.items = respond.data.data
        this.loading.table = false
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data' })
        this.loading.table = false
      }
    },
    handleScroll() {},
  },
}
</script>

<style lang="scss" scoped>
.infinite-scroll {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
</style>
